import {primaryColor, secondaryColor, tertiaryColor, title} from "../../../nextjs-material-kit.js";
import {container} from "../../../nextjs-material-kit.js";
import {featureImageSize} from "../../const";

const productStyle = (theme) => ({
  section: {
    padding: "0 0",
    textAlign: "center",
  },
  mainCard: {
    flexGrow: "1", // Allow read more buttons to align at the end without title height hacks
    display: "flex",
    flexDirection: "column",
  },
  imageContainer: {
    alignSelf: "center",
    minHeight: featureImageSize,
  },
  readMoreBtn: {
    height: "35px",
    minWidth: "110px",
    fontSize: "16px",
    marginTop: "0",
    fontFamily: "var(--font-brandon-grotesque)",
    fontWeight: 700,
    textTransform: "none",
    marginBottom: "14px",
    boxShadow: "none",
    padding: "0",
    borderRadius: "7px",
    "@media (max-width: 768px)": {
      width: "100% !important",
    },
  },
  cardTitle: {
    color: "#095A70",
    fontFamily: "var(--font-brandon-grotesque)",
    fontWeight: 900,
    padding: "0 20px",
    fontSize: "17px",
    lineHeight: "17.6px",
    textAlign: "left",
    margin: "15px 0 8px",
    [theme.breakpoints.down('lg')]: {
      fontSize: "13px",
    },
  },
  cardDesc: {
    color: "#095A70",
    padding: "0 20px",
    textAlign: "left",
    fontSize: "13px",
    lineHeight: "16px",
    [theme.breakpoints.down('lg')]: {
      fontSize: "10px",
    },
  },
  jobCard: {
    height: "100%",
    borderRadius: "0px",
    boxShadow: "none",
    color: "#ffffff",
    paddingTop: "0px",
    marginBottom: "0 !important",
    [theme.breakpoints.down('lg')]: {
      margin: "10px 0",
    },
  },
  gotyou: {
    paddingLeft: "20px",
    paddingRight: "20px",
    fontSize: "1rem",
    textAlign: "center",
    height: "100%",
    minHeight: "72px",
    [theme.breakpoints.down('lg')]: {
      margin: "10px 0",
      minHeight: "100px",
      fontSize: "18px",
    },
    [theme.breakpoints.down('md')]: {
      minHeight: "50px",
    },
  },
  imgFluid: {
    alignSelf: "center",
    maxWidth: "100%",
    maxHeight: featureImageSize,
  },
  green: {
    backgroundColor: primaryColor,
  },
  blue: {
    backgroundColor: tertiaryColor,
  },
  purple: {
    backgroundColor: secondaryColor,
  },
  max_width: {
    maxWidth: "60%",
    position: "absolute",
    top: "0",
    left: "50%",
    transform: "translate(-25%, -105px)",
  },
  title: {
    textDecoration: "none",
    fontFamily: "var(--font-brandon-grotesque)",
    fontWeight: 900,
    fontSize: "6.5em",
    textAlign: "center",
  },
  container: {
    maxWidth: "79%",
    margin: "auto !important",
    marginTop: "0px !important",
    [theme.breakpoints.down('lg')]: {
      maxWidth: "90%",
    },
    "@media (max-width: 1024px)": {
      maxWidth: "97% !important",
    },
  },
  boxContainer: {
    width: "100%",
    display: "flex",
    margin: "auto",
  },
  cardContainer: {
    width: "300px",
    minWidth: "300px",
    height: "auto",
    [theme.breakpoints.down("md")]: {
      marginBottom: "20px",
      padding: 0,
      width: "100%",
    },

    a: {
      textDecoration: "none",
    },
  },
  description: {
    color: "#999",
  },
  customHeading: {
    fontFamily: "var(--font-brandon-grotesque)",
    fontWeight: 900,
    color: "#095a70",
    fontSize: "60px",
  },

  primaryColor: {
    backgroundColor: "#095a70",
  },
  seeMoreBtn: {
    marginTop: "28px",
    height: "35px",
    borderRadius: "6px",
    fontFamily: "var(--font-brandon-grotesque)",
    fontWeight: 700,
    fontSize: "16px !important",
    boxShadow: "none",
    textTransform: "none !important",
    [theme.breakpoints.down('md')]: {
      fontSize: "18px !important",
      height: "45px",
      borderRadius: "8px",
      padding: "0 40px",
      marginTop: "10px",
    },
  },
  seeMore: {
    display: "flex",
    justifyContent: "center",
  },
});

export default productStyle;
