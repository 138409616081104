import { featureImageSize } from '../../const';
import {cardTitle, container, secondaryColor, title} from "../../../nextjs-material-kit.js";
import imagesStyles from "../../imagesStyles";

const carouselStyle = (theme) => ({
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
  },
  primaryColor: {
    backgroundColor: "#095a70",
    fontSize: "13px",
    textTransform: "none",
    fontFamily: "var(--font-brandon-grotesque)",
    fontWeight: 700,
    padding: "15px",
    height: "30px",
  },
  seeMoreBtn: {
    fontSize: "16px",
    [theme.breakpoints.down('md')]: {
      borderRadius: "8px",
      fontSize: "18px",
      height: "42px",
      padding: "24px 30px",
    },
  },
  ...imagesStyles,
  imgFluid: {
    ...imagesStyles.imgFluid,
    alignSelf: "center", // Allow vertically centring inside flex container, without stretching.
    maxHeight: featureImageSize,
    maxWidth: featureImageSize,
    marginTop: "0", // override 10px from imagesStyles

    img: {
      width: "100%",
    }
  },
  logoGridItem: {
    padding: "10px",
    background: "#eaf6fe",
    height: "150px",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
  },
  gridOuterContainer: {
    display: "flex",
    justifyContent: "space-around",
    marginLeft: "0",
    marginRight: "0",
    paddingLeft: "0",
    paddingRight: "0",
  },
  gridItemContainer: {
    width: "325px",
    minWidth: "325px",
    padding: "0", // Override styled grid defaults
    [theme.breakpoints.down('lg')]: {
      minWidth: "unset",
    },
  },
  carouselContainer: {
    padding: "0 !important",
    marginLeft: "0px ",
    marginRight: "0px",

    ".slick-track": {
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      }
    },
  },
  cardTitle: {
    ...cardTitle,
    marginTop: "11px",
    fontSize: "1rem",
    marginBottom: "0 !important",
    color: secondaryColor,
    fontFamily: "var(--font-brandon-grotesque)",
    fontWeight: 900,
    letterSpacing: "1px",
  },
  smallTitle: {
    color: "#000",
    fontFamily: "var(--font-brandon-grotesque)",
    fontSize: "17px !important",
    top: "-4px",
    position: "relative",
    letterSpacing: "1px",
  },
  justIcon: {
    marginLeft: 0,
    height: "30px",
    marginTop: 0,
    paddingTop: 0,
  },
  sideIcon: {
    width: "auto",
    paddingRight: "0",
    minWidth: "auto",
    height: "auto",
    paddingLeft: "4px",
    marginTop: "0",
    paddingTop: "0px",
    minHeight: "auto",
    marginBottom: "0",
    paddingBottom: "0",
    lineHeight: 3,
    right: "8px",
    position: "absolute",
  },
  sideRightIcon: {
    float: "right",
    right: "-20px",
    top: "0px",
  },
  description: {
    color: "#999",
  },
  justifyCenter: {
    justifyContent: "center !important",
    paddingBottom: "15px !important",
    paddingLeft: "0 !important",
    paddingTop: "0 !important",
    float: "left !important",
  },
  iconDesc: {
    float: "left",
    display: "block",
    paddingLeft: 0,
  },
  socials: {
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px",
    color: "#999",
  },
  margin5: {
    fontSize: "9px",
    textTransform: "capitalize",
    textAlign: "left",
    marginLeft: "0px",
    textIndent: "0px",
    minWidth: "75px",
    height: "25px",
  },
  //Carousel Styles
  centerDiv: {
    marginTop: "10px !important",
    margin: "auto",
    padding: "10px",
    width: "100%",
    textAlign: "center",
    marginBottom: "6px !important",
  },
  leftAlign: {
    padding: "0px 10px 15px",
  },
  locationTab: {
    marginLeft: "-2px",
  },
  small: {
    paddingLeft: "5px",
    marginBottom: "-11px",
    lineHeight: "1.5",
    fontFamily: "var(--font-brandon-grotesque)",
    textTransform: "initial",
    fontSize: "13px",
    textIndent: "0px",
    color: "#000",
  },
  jobIcons: {
    color: secondaryColor,
    verticalAlign: "middle",
    marginRight: "5px",
  },
  section: {
    padding: "30px 0 50px",
  },
  container: {
    marginTop: "12px",
    // TODO not really sure why only 325px width cards align best
    // with this width.
    maxWidth: "998px", // 312px * 3 + 30px total margin * 2
    margin: "auto",
    paddingBottom: "30px",
    [theme.breakpoints.down('lg')]: {
      maxWidth: "90%",
    },
  },
  imgIcon: {
    top: "5px",
    width: "18px",
    height: "18px",
    display: "inline-block",
    position: "relative",
    verticalAlign: "middle",
    margin: "0",
  },
});

export default carouselStyle;
