import React, { useState } from "react";
import { useRouter } from "next/router";
// @material-ui/core components
import { makeStyles } from "tss-react/mui";
import { Stack } from "@mui/material";
import { Box, TextField } from "@mui/material";
import DOMPurify from "isomorphic-dompurify";

// core components
import Header from "../components/Header/Header.js";
import Footer from "../components/Footer/Footer.js";
import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";
import Button from "../components/CustomButtons/Button.js";
import HeaderLinks from "../components/Header/HeaderLinks.js";
import Parallax from "../components/Parallax/Parallax.js";
import CustomInput from "../components/CustomInput/CustomInput.js";
import TopJobsCarousel from "../pages-sections/Components-Sections/TopJobsCarousel.js";
import logo from "../public/img/logo.png";

import styles from "../styles/jss/nextjs-material-kit/pages/landingPage.js";

// Sections for this page
import ProductSection from "../pages-sections/LandingPage-Sections/ProductSection.js";
import TopJobs from "../pages-sections/LandingPage-Sections/TopJobs.js";
import WeGotYou from "../pages-sections/LandingPage-Sections/WeGotYou.js";
import NeedAdvice from "../pages-sections/LandingPage-Sections/NeedAdvice.js";
import LatestNews from "../pages-sections/LandingPage-Sections/LatestNews.js";
import GetOnBoard from "../pages-sections/LandingPage-Sections/GetOnBoard.js";
import PreFooter from "../pages-sections/LandingPage-Sections/PreFooter.js";
import Head from "next/head";
import JSONSchemaData from "../components/JSONSchemaData/JSONSchemaData";
import headerBg from "../public/img/home-bg.jpg";

const dashboardRoutes = [];
const useStyles = makeStyles()(styles);

export default function LandingPage(props) {
  const { classes, cx } = useStyles();
  const router = useRouter();
  const [registerModal, setRegisterModal] = useState(false);

  const { ...rest } = props;

  const handleJobSearchClick = async (e) => {
    e.preventDefault();

    window.gtag &&
    window.gtag("event", "job_search", {
      search_term: keyword,
    });

    await router.push(
      `/jobs?query=${encodeURIComponent(
        document.getElementById("job-search-term").value
      )}`
    );
  };

  const jsonBreadcrumbData = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Home",
        item: "https://successatschool.org/",
      },
    ],
  };

  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        logo={logo}
        rightLinks={<HeaderLinks />}
        registerModal={registerModal}
        setRegisterModal={setRegisterModal}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      <Head>
        <link rel="canonical" href="https://successatschool.org" />
      </Head>
      <Parallax size="lg" filter image={headerBg}>
        <form onSubmit={handleJobSearchClick} className={classes.container}>
          <GridContainer
            style={{ width: "103%" }}
            className={classes.paddingZero}
          >
            <GridItem xs={12} sm={12} md={12} className={classes.paddingZero}>
              <h1 className={cx(classes.title, "title")}>
                Explore your future
              </h1>
              <div
                id="inputs"
                className={[classes.search_section, "search_section"].join(" ")}
              >
                <div className={classes.searchbox_title}>
                  Search and apply for the latest opportunities
                </div>
                <GridContainer
                  style={{ alignItems: "center", padding: "15px 0" }}
                >
                  <GridItem xs={12} sm={5} md={5} lg={5}>
                    <CustomInput
                      inputProps={{
                        placeholder: "Job title, keywords, company",
                        className: cx(
                          classes.inputField,
                          "whatField",
                          "whatField-1"
                        ),
                      }}
                      homeSearchBaseInput
                      homeSearch
                      id="job-search-term"
                      formControlProps={{
                        fullWidth: true,
                        fontSize: "18px",
                      }}
                    />
                  </GridItem>

                  <GridItem
                    xs={12}
                    sm={2}
                    md={2}
                    lg={2}
                    className={cx(classes.paddingZero, classes.marginBottom8)}
                  >
                    <Button
                      type="submit"
                      className={cx(classes.homeSearchButton)}
                      fullWidth
                    >
                      Search
                    </Button>
                  </GridItem>
                </GridContainer>
              </div>
              <div className={classes.space70} />
              <br />
            </GridItem>
          </GridContainer>
        </form>
      </Parallax>
      <div>
        <div className={cx(classes.colored_bg, "colored_bgg")}>
          <ProductSection />
        </div>
        <div className={cx(classes.white_bg)}>
          <TopJobs />
          <TopJobsCarousel />
        </div>
        <div
          className={cx(classes.colored_bg, classes.we_got_you, "colored_bgg")}
        >
          <WeGotYou />
        </div>
        <div
          className={cx(
            classes.white_bg,
            "colored_bgg",
            "noTopMargin",
            "noTopPadding",
            "noBottomMargin",
            "noBottomPadding",
            "needAdvice"
          )}
        >
          <NeedAdvice />
        </div>
        <div className={classes.latest_news}>
          <LatestNews />
        </div>
        <div className={classes.getOnBoard}>
          <GetOnBoard />
        </div>
      </div>
      <div className={cx(classes.darkBlue, classes.workSection)}>
        <PreFooter />
      </div>
      <div className={classes.footer}>
        <Footer />
      </div>
      <JSONSchemaData key_name={"breadcrumbSchema"} data={jsonBreadcrumbData} />
    </div>
  );
}
