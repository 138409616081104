import React from "react";
import Link from "next/link";
// @material-ui/core components
import { makeStyles } from 'tss-react/mui';

// core components
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";

import styles from "../../styles/jss/nextjs-material-kit/pages/landingPageSections/LatestNewsStyle.js";
import {useArticlesListRequest} from "../../request/api";
import {buildSlug} from "../../util/url";
import Image from "next/image";

const useStyles = makeStyles()(styles);

export default function LatestNews() {
  const { data, error, isLoading } = useArticlesListRequest({
    article_type: 'news',
    limit: 3,
  });
  const { classes, cx } = useStyles();

  return (
    <div className={cx(classes.container)}>
      <GridContainer style={{ margin: "auto" }}>
        <GridItem xs={12} sm={12} md={12}>
          <h1
            style={{ fontSize: "37px" }}
            className={cx(classes.title, classes.customHeading)}
          >
            Latest News
          </h1>
        </GridItem>

        { isLoading
          ? <div>Loading...</div>
          : <GridContainer className={classes.boxContainer}>
              { data?.articles.map((article) =>
                <GridItem
                  key={'news-' + article.id}
                  xs={12}
                  sm={12}
                  md={4}
                  className={classes.cardContainer}
                >
                  <Card
                    style={{ background: "#eaf6fe" }}
                    className={cx(classes.jobCard, "gotyou")}
                  >
                    <div className={classes.mainCard}>
                      <div className={classes.imageContainer}>
                        <Image
                            width={145}
                            height={145}
                            style={{
                              width: "100%",
                            }}
                            objectFit="contain"
                            src={article.image_uri}
                            role="presentation"
                            className={classes.imgFluid}
                        />
                      </div>
                      <h4 className={classes.cardTitle}>
                        {article.title}
                      </h4>
                      <p className={classes.cardDesc}>
                        {article.content_snippet}
                      </p>
                    </div>
                    <div className={cx("MuiGrid-styledGridItem")}>
                      <Button
                        className={cx(
                          classes.lightColor,
                          classes.readMoreBtn,
                          "button",
                        )}
                        color="info"
                        title={`Read more about ${article.title}`}
                        href={`/news/${buildSlug(article.category)}/${buildSlug(article.title)}/${article.id}`}
                        component={Link}
                      >
                        Read more
                      </Button>
                    </div>
                  </Card>
                </GridItem>
              )}
            </GridContainer>
        }
      </GridContainer>
      <div className={classes.seeMore}>
        <Button
          className={cx(classes.primaryColor, classes.seeMoreBtn, "button")}
          color="info"
          component={Link}
          href="/news"
        >
          See more
        </Button>
      </div>
    </div>
  );
}
