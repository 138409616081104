import React from "react";
import Link from "next/link";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { makeStyles } from 'tss-react/mui';
// @mui/icons-material

// core components
import styles from "../../styles/jss/nextjs-material-kit/components/jobCardStyle.js";

import GridContainer from "../Grid/GridContainer.js";
import GridItem from "../Grid/GridItem.js";
import Button from "../CustomButtons/Button.js";
import Card from "../Card/Card.js";
import CardFooter from "../Card/CardFooter.js";

import CopyLinkIcon from "../../public/img/icons/link.svg?svgr";
import {Stack} from "@mui/material";
import Calendar from "../../public/img/calendar.svg?svgr";
import Location from "../../public/img/location.svg?svgr";
import {format as formatDate} from "../../util/date";
import {buildSlug} from "../../util/url";
import {getDeadline} from "../../pages-sections/Components-Sections/jobList";

const useStyles = makeStyles()(styles);

export default function JobCard(props) {
  const { classes, cx } = useStyles();

  const {job} = props;

  return (
    job && (
      <Card className={cx(classes.jobCard)}>
        <Link href={`/${buildSlug(job.type)}/${buildSlug(job?.organisation.name)}/${buildSlug(job.jobType)}/${buildSlug(job.title)}/${job.id}`}>
          <h4 className={classes.cardTitle}>
            <span style={{fontSize: "17px", letterSpacing: "-0.5px"}}>
              {" "}
              {job.title}
            </span>
            <Button
              justIcon
              color="transparent"
              className={cx(classes.sideIcon)}
            >
              <CopyLinkIcon
                style={{
                  width: "19px",
                  marginBottom: "10px",
                }}
                className={cx(classes.jobIcons, classes.socials)}
              />
            </Button>
          </h4>
        </Link>
        <CardFooter
          className={classes.cardFooter}
        >
          <GridContainer className={classes.glimpse}>
            <GridItem className={classes.iconDesc} xs={6} sm={6} md={6}>
              <Calendar
                className={cx(classes.jobIcons)}
                height="17"
                width="18"
              />
              <small className={classes.small}>
                {getDeadline(job)}
              </small>
            </GridItem>

            <GridItem
              className={cx(classes.iconDesc, classes.locationTab)}
              xs={6}
              sm={6}
              md={6}
            >
              <Location
                className={cx(classes.jobIcons)}
                height="19"
                width="15"
              />
              <small
                className={classes.small}
              >
                {job.location_name}
              </small>
            </GridItem>
          </GridContainer>
        </CardFooter>
      </Card>
    )
  );
}

JobCard.propTypes = {
  job: PropTypes.object, // Should be Job-like, but TS interfaces don't make it to runtime script.
};
