import {
  container,
  primaryColor,
  secondaryColor,
  tertiaryColor,
  tertiaryColorTint,
  title
} from "../../nextjs-material-kit.js";

const landingPageStyle = (theme) => ({
  latest_news: {
    padding: "30px 0",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down('lg')]: {
      height: "100% !important",
    },
    [theme.breakpoints.down('md')]: {
      height: "100% !important",
    },
  },
  container: {
    marginBottom: "7%",
    zIndex: "12",
    height: "auto",
    margin: "auto",
    width: "100%",
    ...container(theme),
  },

  title: {
    display: "inline-block",
    position: "relative",
    marginTop: "260px",
    minHeight: "32px",
    color: "#FFFFFF",
    textDecoration: "none",
    fontFamily: "var(--font-brandon-grotesque)",
    fontWeight: 900,
    fontSize: "5.0em",
    left: "0%",
    transform: "translateX(0%)",
    marginBottom: "20px",
    [theme.breakpoints.down('xl')]: {
      marginTop: "20px !important",
    },
  },
  searchbox_title: {
    fontSize: "18px",
    fontFamily: "var(--font-brandon-grotesque)",
  },
  search_section: {
    marginRight: "100px", // Give the arrow decoration visible space without this overlapping.
    padding: "10px 20px",
    background: "#095A70",
    color: "#ffffff",
    opacity: 0.86,
    [theme.breakpoints.down('md')]: {
      marginRight: "10px",
    },
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0",
  },
  main: {
    position: "relative",
    zIndex: "3",
  },
  darkBlue: {
    backgroundColor: primaryColor,
  },
  lightGreen: {
    backgroundColor: secondaryColor,
    paddingTop: "7px",
    paddingBottom: "8px",
    marginTop: "22px",
  },
  homeSearchButton: {
    fontSize: "18px",
    marginTop: "0",
    fontFamily: "var(--font-brandon-grotesque)",
    fontWeight: 700,
    textTransform: "none",
    backgroundColor: tertiaryColor,
    marginBottom: "0px",
    lineHeight: "16px",
    display: "flex",
    boxShadow: "none",
    padding: "6.5px 30px",
    "&:hover": {
      backgroundColor: tertiaryColorTint,
    },
  },
  colored_bg: {
    background: "#eaf6fe",
  },
  paths_ahead: {
    minHeight: "270px",
    paddingBottom: "0",
  },
  we_got_you: {
    padding: "20px 0 30px",
    [theme.breakpoints.up('md')]: {
      padding: "40px 0 80px",
    },
  },
  white_bg: {
    background: "#ffffff",
  },
  getOnBoard: {
    background: "#eaf6fe",
    minHeight: "280px",
  },
  workSection: {
    height: "270px",
    "@media (max-width: 1023px)": {
      height: "100% !important",
    },
  },
  footer: {
    height: "55px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  paddingZero: {
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
  marginBottom8: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: "8px",
    },
  },
});

export default landingPageStyle;
