import React from "react";
// react component for creating beautiful carousel
import Carousel from "react-slick";
import Link from "next/link";
import Image from "next/image";

import { makeStyles } from 'tss-react/mui';

// core components
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Button from "../../components/CustomButtons/Button.js";

import styles from "../../styles/jss/nextjs-material-kit/pages/componentsSections/carouselStyle.js";
import JobCard from "../../components/JobCard/JobCard.js";
import {useJobsListRequest} from "../../request/api";
import Grid from "@mui/material/Grid";
import {useTheme} from "@mui/material";
import {buildSlug} from "../../util/url";

const useStyles = makeStyles()(styles);

export default function TopJobsCarousel() {
  const theme = useTheme();
  const { data, error, isLoading } = useJobsListRequest({
    limit: 9,
    offset: 0,
    featured: true,
  });
  let topJobs = [];
  if (data) {
    topJobs = data.jobs;
  }

  const { classes, cx } = useStyles();
  const imageClasses = cx(classes.imgFluid);
  const settings = {
    adaptiveHeight: false, // Didn't seem to help with our job card's (+ associated bits') height alignment.
    arrows: false,
    autoplay: false,
    dots: true,
    infinite: (topJobs.length > 3),
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: theme.breakpoints.values['md'],
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: theme.breakpoints.values['sm'],
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={classes.container}>
      <Grid container>
        <Grid item xs={12} className={classes.carouselContainer}>
          <Carousel {...settings}>
            {topJobs.length === 0 ? null : topJobs.map((job, i) => (
              // Each job needs its own top level plain div for react-slick.
              // https://github.com/akiran/react-slick#example
              <div key={`slide-job-${i}`} className="link carousel-item-container">
                <Grid item className={classes.logoGridItem}>
                  <span
                      className={imageClasses}
                  >
                      <Link href={`/${buildSlug(job.type)}/${buildSlug(job?.organisation.name)}/${buildSlug(job.jobType)}/${buildSlug(job.title)}/${job.id}`}>
                        <img
                            src={job.organisation.logo_uri}
                            alt={job.organisation.name + ' logo'}
                        />
                      </Link>
                    </span>
                </Grid>
                <Grid item>
                  <JobCard job={job} />
                </Grid>
              </div>
            ))}
          </Carousel>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <div className={classes.centerDiv}>
            <Button
              style={{boxShadow: "none"}}
              className={cx(classes.primaryColor, classes.seeMoreBtn)}
              color="info"
              component={Link}
              href="/jobs"
            >
              See more jobs!
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
