import Head from 'next/head';

export default function JSONSchemaData({data, show_breadcrumb = false, key_name = 'structured_data'}) {
  return (
      <Head>
        {data && (<script
            key={key_name}
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
        />)}
      </Head>
  )
}