import {cardTitle, secondaryColor, sidebarColor, title} from "../../nextjs-material-kit.js";
import imagesStyle from "../imagesStyles.js";

const jobCardStyle = (theme) => ({
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    textDecoration: "none",
  },
  primaryColor: {
    backgroundColor: "#095a70",
  },
  ...imagesStyle,
  cardTitle: {
    ...cardTitle,
    textAlign: "center",
    marginTop: "11px",
    fontSize: "1.2rem",
    marginBottom: "0 !important",
    color: sidebarColor,
    fontFamily: "var(--font-brandon-grotesque)",
    fontWeight: 900,
    letterSpacing: "1px",
    lineHeight: "1.2rem",
  },
  sideIcon: {
    width: "auto",
    paddingRight: "0",
    minWidth: "auto",
    height: "auto",
    paddingLeft: "4px",
    marginTop: "0",
    paddingTop: "0px",
    marginBottom: "0",
    paddingBottom: "0",
    lineHeight: 3,
    right: "8px",
    position: "absolute",
  },
  iconDesc: {
    padding: 0,
    textAlign: "center",
  },
  glimpse: {
    margin: 0,
    justifyContent: "space-between",
    width: "100%",
  },
  socials: {
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px",
    color: "#999",
  },

  //Carousel Styles
  centerDiv: {
    margin: "20px auto 0 !important",
    width: "156px",
    border: "0px solid #73AD21",
    padding: "0",
  },
  leftAlign: {
    padding: "0px 10px 15px",
  },
  small: {
    fontFamily: "var(--font-brandon-grotesque)",
    textTransform: "initial",
    fontSize: "13px",
    lineHeight: "1.4rem",
    color: "#000",
  },
  jobIcons: {
    fill: sidebarColor,
    color: sidebarColor,
    width: "15px",
    verticalAlign: "middle",
    marginRight: "5px",
  },
  jobDetails: {
    alignItems: "center",
  },
  section: {
    padding: "30px 0 25px",
  },
  jobCard: {
    padding: "0 30px",
    marginTop: "0",
    boxShadow: "none",

    [theme.breakpoints.down("md")]: {
      margin: 0,
    },

    a: {
      textDecoration: "none",
    },
  },
  cardFooter: {
    padding: '0.9375rem 0',
  },
  locationStack: {
    alignItems: "flex-start",
  },
});

export default jobCardStyle;
